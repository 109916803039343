html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}


html {
    font-size: 14px;
}

a {
    color: inherit !important;
    text-decoration: none !important;
}

* {
    font-family: "Greenwich" !important;
    box-sizing: border-box;
}
h1,h2,h3,h4,h5,h6{
    font-weight: 500 !important;
    color:#221e54;
}
.form {
    min-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 20px;
}

.form .form__custom-button {
    margin-top: 50px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.richtext-toolbar {
    border-style: none !important;
}

.richtext-wrapper {
    border: 1px solid #efefef;
}

.richtext-editor {
    padding: 10px;
    min-height: 100px;
}

.richtext-toolbar a,
.richtext-toolbar a:hover {
    color: black;
    font-size: 13px !important;
}

.richtext-toolbar img {
    width: 10px !important;
}
