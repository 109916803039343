/* black */
@font-face {
    font-family: 'Greenwich';
    font-weight: 800;
    src: local('Greenwich'), url(./Greenwich/Greenwich-Black.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 800;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-BlackItalic.otf) format('opentype');
}

/* bold */
@font-face {
    font-family: 'Greenwich';
    font-weight: 600;
    src: local('Greenwich'), url(./Greenwich/Greenwich-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 600;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-BoldItalic.otf) format('opentype');
}

/* extra bold */
@font-face {
    font-family: 'Greenwich';
    font-weight: 650;
    src: local('Greenwich'), url(./Greenwich/Greenwich-ExtraBold.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 650;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-ExtraBoldItalic.otf) format('opentype');
}

/* extra light */
@font-face {
    font-family: 'Greenwich';
    font-weight: 250;
    src: local('Greenwich'), url(./Greenwich/Greenwich-ExtraLight.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 250;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-ExtraLightItalic.otf) format('opentype');
}

/* light */
@font-face {
    font-family: 'Greenwich';
    font-weight: 300;
    src: local('Greenwich'), url(./Greenwich/Greenwich-Light.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 300;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-LightItalic.otf) format('opentype');
}

/* medium */
@font-face {
    font-family: 'Greenwich';
    font-weight: 350;
    src: local('Greenwich'), url(./Greenwich/Greenwich-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 350;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-MediumItalic.otf) format('opentype');
}

/* regular */
@font-face {
    font-family: 'Greenwich';
    src: local('Greenwich'), url(./Greenwich/Greenwich-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-Italic.otf) format('opentype');
}

/* semi bold*/
@font-face {
    font-family: 'Greenwich';
    font-weight: 500;
    src: local('Greenwich'), url(./Greenwich/Greenwich-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 500;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-SemiBoldItalic.otf) format('opentype');
}

/* thin */
@font-face {
    font-family: 'Greenwich';
    font-weight: 200;
    src: local('Greenwich'), url(./Greenwich/Greenwich-Thin.otf) format('opentype');
}

@font-face {
    font-family: 'Greenwich';
    font-weight: 200;
    font-style: italic;
    src: local('Greenwich'), url(./Greenwich/Greenwich-ThinItalic.otf) format('opentype');
}
